import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useRouter } from 'next/router';
import {
  IconButton,
  InputAdornment,
  InputLabel,
  NoSsr,
  Skeleton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AuthService from 'src/services/AuthService';
import UserService from 'src/services/UserService';
import ErrorMessage from '@components/common/ErrorMessage';
import { AuthMode, COMMON_ERRORS, ReactQueryKeys } from 'src/utils/Constants';
import { routeLayout } from 'src/paths';
import { updateAbility } from 'src/context/ability/Ability';
import { useSnackBars } from 'src/context/Snackbar/SnackBarContext';
import * as S from 'src/components/pages/login/Login.styled.js';
import SelectRoles from './SelectRoles';
import { isLocalhost } from '@utils/Functions';

const defaultValues = {
  IdUser: isLocalhost() ? '1413' : '',
  User: isLocalhost() ? '1413' : '',
  roles: isLocalhost() ? 'employee' : 'employee',
};

export default function Login() {
  const router = useRouter();
  const { debug = false, redirectTo = false } = router.query;
  const [isTokenSaved, setIsTokenSaved] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const selectedRoles = useRef([]).current;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });
  const { showErrorSnackbar } = useSnackBars();

  if (errors?.length > 0) console.error(errors);

  const onSubmit = (values) => {
    if (!router?.isReady) return false;
    const credentials = buildRequestForm(values);
    logIn(credentials);
  };

  const buildRequestForm = (formValues) => {
    let requestValues = {
      username: formValues.User,
      password: formValues.Password,
    };
    if (debug) {
      requestValues.id_user = formValues.IdUser || defaultValues.IdUser;
      requestValues.username = formValues.User || defaultValues.User;
      requestValues.roles = selectedRoles.toString() || defaultValues.roles;
      requestValues.authentication = AuthMode.INTERNAL;
    }
    return requestValues;
  };

  const { mutate: logIn } = useMutation(AuthService.login, {
    onSuccess: onLoginSuccess,
    onError: onLoginError,
  });

  function onLoginSuccess(response) {
    setIsTokenSaved(AuthService.saveTokenInLocalStorage(response));
  }

  function onLoginError(errorDetail) {
    const errorText =
      errorDetail?.message || COMMON_ERRORS[errorDetail?.response_message];
    showErrorSnackbar(errorText);
  }

  useQuery(
    [ReactQueryKeys.CURRENT_USER, isTokenSaved],
    UserService.getCurrentUser,
    {
      enabled: isTokenSaved,
      onSuccess: onGetCurrentUserSuccess,
      onError: onGetCurrentUserError,
    }
  );

  function onGetCurrentUserSuccess(response) {
    UserService.saveCurrentUserInLocalStorage(response);
    updateAbility();
    redirectToPageLogged();
  }

  const redirectToPageLogged = () => {
    const urlForRedirect = redirectTo ? redirectTo : routeLayout.home;
    router.replace(urlForRedirect);
  };

  function onGetCurrentUserError(error) {
    const errorText = COMMON_ERRORS[error?.response_message];
    showErrorSnackbar(errorText);
    console.error('Get Current User Error: ', errorText);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} method="post">
      {process.env.NODE_ENV === 'development' && (
        <>
          <S.Link
            to={
              redirectTo
                ? '/?debug=true&redirectTo=' + redirectTo
                : '/?debug=true'
            }
          >
            Debug mode
          </S.Link>
          <br />
          <p>1463: days: carrera_afin: true, ongoing_studies: true </p>
          <p>1413: days: carrera_afin: false, ongoing_studies: true </p>
          <p>1478: super_admin </p>
          <a
            href="https://docs.google.com/document/d/1P88CpRZRoVaZu3mDDVOQbw56b9iAx_2mCvWNHI5_Pro/edit"
            target="user_tests"
          >
            Usuarios de prueba
          </a>
        </>
      )}
      <S.InputArea>
        <NoSsr
          fallback={
            <Skeleton
              variant="rectangular"
              height={54}
              sx={{
                margin: '16px 0',
                width: { xs: 'calc(100vw - 40px)', sm: '400px' },
                maxWidth: { xs: '400px', sm: 'none' },
              }}
            />
          }
        >
          <S.FormControl variant="standard">
            <InputLabel htmlFor="User">Usuario ARSAT</InputLabel>
            <S.Input
              id="User"
              style={{
                margin: 0,
              }}
              {...register('User', { required: 'Debe ingresar su usuario.' })}
              autoFocus
              placeholder="Usuario"
              type="text"
            />
            <ErrorMessage errors={errors} name="User" />
          </S.FormControl>
        </NoSsr>
        {!debug && (
          <NoSsr
            fallback={
              <Skeleton
                variant="rectangular"
                height={54}
                sx={{
                  margin: '16px 0 32px',
                  width: { xs: 'calc(100vw - 40px)', sm: '400px' },
                  maxWidth: { xs: '400px', sm: 'none' },
                }}
              />
            }
          >
            <S.FormControl variant="standard">
              <InputLabel htmlFor="Password">Contraseña</InputLabel>
              <S.Input
                {...register('Password', {
                  required: 'Debe ingresar su contraseña.',
                })}
                id="Password"
                style={{
                  margin: 0,
                  marginBottom: '1rem',
                }}
                placeholder="Contraseña"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={toggleShowPassword}
                      aria-label={
                        showPassword
                          ? 'Mostrar contraseña'
                          : 'Ocultar Contraseña'
                      }
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <ErrorMessage errors={errors} name="Password" />
              {/* <Feature name="RESET_PASSWORD">
            <S.Link to={'/'}>Olvidé mi usuario / contraseña</S.Link>
          </Feature> */}
            </S.FormControl>
          </NoSsr>
        )}
        {debug && (
          <NoSsr
            fallback={
              <Skeleton
                variant="rectangular"
                height={54}
                sx={{
                  margin: '16px 0 32px',
                  width: { xs: 'calc(100vw - 40px)', sm: '400px' },
                  maxWidth: { xs: '400px', sm: 'none' },
                }}
              />
            }
          >
            <S.FormControl variant="standard">
              <S.FormControl variant="standard">
                <InputLabel htmlFor="component-simple">ID Usuario</InputLabel>
                <S.Input
                  style={{
                    margin: 0,
                  }}
                  {...register('IdUser')}
                  autoFocus
                  placeholder="ID Usuario"
                  type="text"
                />
                <ErrorMessage errors={errors} name="IdUser" />
              </S.FormControl>
            </S.FormControl>
            <SelectRoles selectedRoles={selectedRoles} register={register} />
          </NoSsr>
        )}
      </S.InputArea>
      <S.SubmitArea>
        <NoSsr
          fallback={
            <S.Button
              width={180}
              fontWeight={400}
              bradius={20}
              type="submit"
              disabled={true}
            >
              Ingresar
            </S.Button>
          }
        >
          <S.Button
            width={180}
            fontWeight={400}
            bradius={20}
            type="submit"
            disabled={!router?.isReady}
          >
            Ingresar
          </S.Button>
        </NoSsr>
      </S.SubmitArea>
    </form>
  );
}
